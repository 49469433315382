<template>
	<swiper
		@slideChange="slideChange"
		:parallax="true"
		:loop="true"
		:autoplay="{
			delay: 5000,
		}"
		:pagination="{
			clickable: true,
			bulletActiveClass: 'activeOption',
			el: '.pagesOptions',
			bulletClass: 'defaultOption swiper-pagination-bullet',
		}"
		:navigation="{
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
			hideOnClick: true,
		}"
		class="homeSwiper"
	>
		<swiper-slide>
			<CalendarEvents />
		</swiper-slide>
		<swiper-slide>
			<CalendarEvents2 class="calendar" />
		</swiper-slide>
		<swiper-slide>
			<Connection class="calendar" />
		</swiper-slide>
		<swiper-slide>
			<Connection2 class="calendar" />
		</swiper-slide>
		<swiper-slide>
			<Connection3 class="calendar" />
		</swiper-slide>
		<swiper-slide>
			<Connection4 class="calendar" />
		</swiper-slide>
		<swiper-slide>
			<Backlog />
		</swiper-slide>
		<swiper-slide>
			<Summary class="calendar" />
		</swiper-slide>
		<div class="pagesOptions"></div>
		<div class="swiper-button-prev hiden"></div>
		<div class="swiper-button-next hiden"></div>
	</swiper>
</template>
<script>
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
//这里导入对应样式
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
//导入后在SwiperCore.use([])里注册
SwiperCore.use([Pagination, Navigation, Autoplay]);
import { useRouter } from "vue-router";
import CalendarEvents from "./tabCont/CalendarEvents.vue";
import CalendarEvents2 from "./tabCont/CalendarEvents2.vue";
import Connection from "./tabCont/Connection.vue";
import Connection2 from "./tabCont/Connection2.vue";
import Connection3 from "./tabCont/Connection3.vue";
import Connection4 from "./tabCont/Connection4.vue";
import Backlog from "./tabCont/Backlog.vue";
import Summary from "./tabCont/Summary.vue";
export default {
	name: "HomeSwiper",

	components: {
		Swiper,
		SwiperSlide,
		CalendarEvents,
		CalendarEvents2,
		Connection,
		Connection2,
		Connection3,
		Connection4,
		Backlog,
		Summary,
	},

	setup() {
		const router = useRouter();
		const slideChange = (swiper) => {
			// console.log(swiper.activeIndex);
			// console.log(arguments);
		};

		const toProduct = () => {
			router.push({ name: "Product" });
		};
		return { slideChange, toProduct };
	},

	methods: {},
	mounted() {},
};
</script>
<style lang="scss">
.homeSwiper {
	width: 100%;
	margin-top: 81px;
	padding-bottom: 70px;
}
.pagesOptions {
	position: absolute;
	bottom: 90px !important;
	right: 8% !important;
	left: auto !important;
	width: auto !important;
	display: inline-block;
	z-index: 2;
	.defaultOption {
		background-color: #f1f3ff;
		width: 14px;
		height: 11px;
		border-radius: 5px;
		opacity: 1;
		display: inline-block;
		margin-right: 5px;
	}
	.activeOption {
		background-color: #5a76e4;
		width: 53px;
	}
}

.swiper-button-prev {
	width: 45px;
	height: 45px;
	background-image: url("../assets/imgs/swiper_button_prev.png");
	background-size: 100% 100%;
	right: 90%;
	z-index: 99999;
	left: auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	&::after {
		content: "";
	}
}
.swiper-button-next {
	width: 45px;
	height: 45px;
	background-image: url("../assets/imgs/swiper_button_next.png");
	cursor: pointer;
	background-size: 100% 100%;
	left: 90%;
	right: auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 99999;
	&::after {
		content: "";
	}
}
.swiper-slide {
	padding: 0 10%;
}
.homeSwiper .calendar {
	justify-content: center;
	.img img {
		height: 750px;
	}
	.text {
		margin-left: 187px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	h2 {
		font-size: 55px;
		color: #192646;
		font-weight: normal;
	}
	h3 {
		color: #8c919c;
		font-size: 22px;
		font-weight: normal;
		margin-top: 120px;
		margin-bottom: 20px;
	}
	p {
		color: #192646;
		font-size: 22px;
		line-height: 1.5;
		letter-spacing: 2px;
	}
	p:nth-of-type(2) {
		margin-top: 20px;
		margin-bottom: 50px;
	}
	.cont {
		display: inline-flex;
		align-items: center;
		height: 67px;
		border-radius: 34px;
		overflow: hidden;
		background-color: rgba(201, 209, 216, 0.24);
		flex-shrink: 0;
		flex-grow: 0;
		width: auto;
		span {
			color: #6e3fc3;
		}
		span:first-child {
			padding: 0 41px;
		}
		span:nth-child(2) {
			padding: 0 31px;
			border-left: 1px solid rgba(201, 209, 216, 0.43);
		}
		span:nth-child(3) {
			padding-left: 31px;
			padding-right: 51px;
			border-left: 1px solid rgba(201, 209, 216, 0.43);
		}
	}
	.more {
		width: 169px;
		height: 67px;
		line-height: 67px;
		text-align: center;
		background-color: #5a76e4;
		box-shadow: 0px 11px 15px 1px rgba(215, 222, 249, 1);
		color: #fff;
		border-radius: 34px;
		font-size: 16px;
		margin-top: 80px;
		cursor: pointer;
	}
	&.calendarConnection {
		.text {
			margin-left: -80px;
			width: 730px;
		}
		.img {
			width: 300px;
		}
	}
}

@media screen and (max-width: 700px) {
	.homeSwiper .calendar {
		h3 {
			margin-top: 90px;
		}
		.img {
			img {
				height: 850px;
			}
		}
		.text {
			width: 50%;
			transform: scale(0.95);
		}
		.more {
			width: 300px;
			height: 110px;
			line-height: 110px;
			border-radius: 55px;
			transform: scale(0.7) translateX(-60px) translateY(-30px);
			margin-top: 30px;
		}
	}
}
@media screen and (max-width: 600px) {
	.homeSwiper {
		padding-bottom: 90px;
		.swiper-wrapper {
			.row {
				.img,
				.text,
				p {
					width: 100% !important;
					margin: 0;
					padding: 0;
					text-align: center;
				}
			}
		}
		.pagesOptions {
			right: 50% !important;
			bottom: 30px !important;
			text-align: center;
			transform: translateX(50%);
		}
	}
}
</style>

<style lang="css">
@import "./tabCont/style.css";
</style>
