<template>
	<div class="calendar row tabCont">
		<div class="img col-3">
			<img
				src="../../assets/imgs/product/contacts_phone_big1.svg"
				alt=""
			/>
		</div>
		<div class="text col-6">
			<h2>人脉信息你做主</h2>
			<p>
				让每次互动都有迹可循，自动化和自定义记录互动内容，避免因记忆模糊导致的疏忽，确保每次互动细节可回溯。
			</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "Connection4",
	setup() {
		const phone2Text = {
			title: "人脉互动记录脉络",
		};
		return {
			phone2Text,
		};
	},
};
</script>
<style lang="css">
@import "./style.css";
</style>
