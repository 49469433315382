<template>
	<div class="calendar row tabCont">
		<div class="img col-3">
			<img
				src="../../assets/imgs/product/contacts_phone_big.svg"
				alt=""
			/>
		</div>
		<div class="text col-6">
			<h2>{{phone2Text.title}}</h2>
			<p>
				丰富的人脉信息模板，当您开始有效管理人脉时,您会发现每个人都需要使用更丰富的信息来维护他的属性，他的城市/爱好/经验/长处等等，所有的人脉信息在模板里都能定义，真正帮助您成为人脉资源的主人。
			</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "Connection2",
	setup() {
		const phone2Text = {
			title: "人脉信息你做主",
		};
		return {
			phone2Text,
		};
	},
};
</script>
<style lang="css">
@import "./style.css";
</style>
