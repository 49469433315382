<template>
	<div class="calendar row tabCont">
		<div class="img col-3">
			<img src="../../assets/imgs/phone1.svg" alt="" />
		</div>
		<div class="text col-6">
			<h2>每一天的安排都能心中有数</h2>
			<p>
				通过日/周/月/事件列表4种日历视图，
				<br class="show" />
				您可以直观地查看各天，各周，各月的事件，不会再为“接下来做什么”而不知所措，还能便捷地拖拽事件来调整日期。
				<br />
				<br class="hiden" />
				您还可根据「艾宾浩斯记忆法」所揭示的记忆规律，对所记事项及时复习，帮助提升记忆。
			</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "CalendarEvents",
};
</script>
<style lang="css">
@import "./style.css";

</style>
