<template>
  <div class="home">
    <!-- 企业title -->
    <div class="companyTitle" :style="{ height: height }">
      <div class="introductionText">
        <h1 class="animated fadeInDown">
          <strong>畅你所想</strong>
          <strong>高效生活</strong>
        </h1>
        <h2 class="animated fadeIn">
          <strong>Effic</strong>iency <strong>Booster</strong>
        </h2>

        <h2 class="animated fadeInUp">
          一家专注于打造最佳效率工具的科技公司
        </h2>
      </div>
    </div>
    <!-- 公司介绍 -->
    <div class="companyIntroduction">
      <dl>
        <dt class="wow animated animate__slideInLeft">
          <img src="../assets/imgs/introduction_img.png" alt="" />
        </dt>
        <dd class="wow animated animate__slideInRight">
          <p>
            <span>
              公司简介
            </span>
            <i></i>
          </p>
          <p>
            北京畅效科技有限公司专注于效率管理，成立于2021年01月29日。
          </p>
          <p>
            公司以用户为中心，以提升效率为核心，致力于打造世界顶级的效率工具，
            旨在为人们提供更加高效、安全可靠的产品。
          </p>
        </dd>
      </dl>
    </div>
    <div class="teamIntroduction ">
      <dl class="hiden">
        <dd class=" wow animated animate__slideInLeft">
          <p>
            <span>
              我们的团队
            </span>
            <i></i>
          </p>
          <p>
            我们是由一群有极客追求的小伙伴组建的专业团队，
            团队的每个人都是效率工具的追随者， 皆秉持着对高效生活的理念。
          </p>
          <p>
            做全球最佳的效率工具是我们的使命与追求，
            我们一直在不断的尝试与创新，
            致力于通过优秀的工具来帮助人们快速成长。
          </p>
        </dd>
        <dt class=" wow animated animate__slideInRight">
          <img src="../assets/imgs/team_img.png" alt="" />
        </dt>
      </dl>
      <dl>
        <dt class=" wow animated animate__slideInLeft">
          <img src="../assets/imgs/cooperation_img.png" alt="" />
        </dt>
        <dd class=" wow animated animate__slideInRight">
          <p>
            <span>
              我的成就，从您的参与开始
            </span>
            <i></i>
          </p>
          <p>
            我们希望每天都有用户主动给身边人分享<br />
            分享自己是如何通过我们的应用来改变每天的工作和生活<br />
            分享是如何改变我们人脉的经营方式<br />
            分享是如何习惯用效率工具来帮助自己成长<br />
          </p>
        </dd>
      </dl>
    </div>
    <div class="productIntroduction">
      <dl>
        <dd>
          <p>
            <span>
              核心产品
            </span>
            <i></i>
          </p>
          <p>
            WOLB缩写自“Well Organized Life Booster”，中译为“高效人生加速器”。
            它是一款集事件安排、日历提醒、人脉管理及纪要待办功能于一体的效率管理工具
          </p>
          <p>
            支持离线操作、多端登录和灵活的个性化人脉模板
          </p>
          <div class="intoMore" @click="intoProduct">
            查看更多>>
          </div>
        </dd>
        <dt class="hiden">
          <img src="../assets/imgs/wolb_logo_img.png" alt="" />
        </dt>
      </dl>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import HomeSwiper from "../components/HomeSwiper.vue";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import { WOW } from "wowjs";
import { onMounted } from "vue";
export default {
  components: { HomeSwiper, KinesisContainer, KinesisElement },
  name: "Home",
  setup() {
    const router = useRouter();
    const height = (window.innerWidth / 3840) * 1948 + "px";
    const intoProduct = () => {
      router.push({ name: "Product" });
    };

    onMounted(() => {
      new WOW({
        live: false,
        scrollContainer: ".main",
      }).init();
    });
    return {
      height,
      intoProduct,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/

  -webkit-user-select: none; /*webkit浏览器*/

  -khtml-user-select: none; /*早期浏览器*/

  -moz-user-select: none; /*火狐*/

  -ms-user-select: none; /*IE10*/

  user-select: none;
}
img {
  pointer-events: none;
}
html,
body {
  position: relative;
  overflow-x: hidden;
}
.home {
  position: relative;
}
.scrollAnimate {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  & > div {
    height: 400px;
  }
}
.companyTitle {
  color: #fff;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/imgs/top_bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  padding-top: 150px;
  h1 {
    width: 48%;
    font-size: 96px;
    display: flex;
    justify-content: space-between;
    letter-spacing: 10px;
    margin: 0 auto;
    strong {
      font-weight: normal;
    }
  }

  h2 {
    font-size: 44px;
    text-align: center;
    letter-spacing: 2px;
    margin-top: 80px;
    font-weight: normal;
    letter-spacing: 1px;
    word-spacing: 30px;
    color: rgba($color: #fff, $alpha: 0.7);
    strong {
      color: rgba($color: #fff, $alpha: 1);
    }
  }
  h2:nth-of-type(2) {
    margin-top: 25px;
    color: rgba($color: #fff, $alpha: 1);
  }
  p {
    font-size: 18px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 20px;
    line-height: 28px;
  }
}
.companyIntroduction,
.productIntroduction,
.teamIntroduction {
  background-color: #f9faff;
  color: #2f3032;
  padding-left: 80px;
  p {
    margin-bottom: 30px;
  }
  p:first-child {
    font-size: 50px;
    position: relative;
    display: inline-block;
    height: 60px;
    z-index: 2;
    margin-bottom: 50px;
    i {
      position: absolute;
      width: 100%;
      background-color: rgba($color: #4c73fe, $alpha: 0.47);
      left: 0;
      bottom: 0;
      height: 18px;
      z-index: 1;
    }
    span {
      position: relative;
      z-index: 2;
      font-size: 50px;
    }
  }
  dl {
    display: flex;
    padding: 90px 95px;
    margin-bottom: 0;
    align-items: center;
  }
  p {
    font-size: 29px;
    text-align: justify;
    &:not(:first-child) {
      line-height: 1.9;
    }
  }
  dd {
    padding-top: 35px;
    padding-right: 80px;
  }
  dt {
    margin-right: 156px;
  }
  img {
    width: 647px;
  }
}
.teamIntroduction {
  background-color: #fff;
  img {
    width: 775px;
  }
  dd {
    padding-right: 100px;
  }
  dt{
    margin-right: 80px;
  }
  dl:nth-of-type(2) {
    dd {
      padding-right: 70px;
    }
    dt {
      margin-right: 80px;
    }
  }
}
.productIntroduction {
  dt{
    margin-right: 50px;
  }
  img {
    width: 474px;
  }
}
.intoMore {
  color: #0a64dd;
  font-size: 29px;
  cursor: pointer;
  margin-top: 60px;
}
@media screen and (max-width: 600px) {
  .companyTitle {
    height: 510px !important;
    background-image: url("../assets/imgs/top_bg_phone.png");
    background-size: 100% 100%;
    h1 {
      width: 100%;
      justify-content: center;
      letter-spacing: 3px;
      strong {
        font-size: 64px;
      }
      strong:first-of-type {
        margin-right: 40px;
      }
    }
    h2 {
      margin-top: 35px;
      word-spacing: 15px;
      color: rgba($color: #fff, $alpha: 0.65);
      font-size: 33px;
      strong {
        font-weight: 500;
      }
    }
    h2:nth-of-type(2) {
      margin-top: 14px;
    }
  }
  .companyIntroduction,
  .productIntroduction,
  .teamIntroduction {
    padding-left: 0;
    dl {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      img {
        width: 100%;
      }
    }
    dt,
    dd {
      margin: 0 !important;
      padding: 0;
    }
    dd {
      width: 832px;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 20px;
      p:first-child i {
        bottom: -8px;
      }
    }
  }

  .companyIntroduction {
    margin-top: -1px;
    dl {
      background-color: #f9faff;
    }
  }
  .teamIntroduction {
    background-color: #eef0f3;
  }
  .productIntroduction {
    background-color: #f9faff;
    padding-bottom: 30px;
  }
  .teamIntroduction dl:nth-of-type(2) dd{
    padding-right: 0;
  }
}
</style>
