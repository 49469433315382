<template>
	<div class="calendarConnection row tabCont">
		<div class="text col-3 hiden">
			<h2>{{ phone2Text.title }}</h2>
			<p>
				可视化我和人脉，人脉和人脉的关系。通过可视化呈现人际网络结构，帮助快速定位核心资源，提升合作效率，为职业发展与资源整合提供直观决策支持。
			</p>
		</div>
		<div class="img col-2">
			<img src="../../assets/imgs/product/contacts_phone1.svg" alt="" />
		</div>
		<div class="text col-3 show">
			<h2>{{ phone2Text.title }}</h2>
			<p>
				可视化我和人脉，人脉和人脉的关系。通过可视化呈现人际网络结构，帮助快速定位核心资源，提升合作效率，为职业发展与资源整合提供直观决策支持。
			</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "Connection3",
	setup() {
		const phone2Text = {
			title: "人脉关系网",
		};
		return {
			phone2Text,
		};
	},
};
</script>
<style lang="css">
@import "./style.css";
</style>
