<template>
	<div class="calendarConnection row tabCont">
		<div class="text col-3 hiden">
			<h2>{{ phone2Text.title }}</h2>
			<p>
				科学有效的管理你的人脉关系，追求更高人生目标时，需有效管理人脉资源。
			</p>
			<p>
				通过维护并加强联系，以群组和标签分层管理人脉，借助圈层助力，提升个人价值，变得更优秀。
			</p>
		</div>
		<div class="img col-2">
			<img src="../../assets/imgs/product/contacts_phone.svg" alt="" />
		</div>
		<div class="text col-3 show">
			<h2>{{ phone2Text.title }}</h2>
			<p>
				科学有效的管理你的人脉关系，追求更高人生目标时，需有效管理人脉资源。<br />
				通过维护并加强联系，以群组和标签分层管理人脉，借助圈层助力，提升个人价值，变得更优秀。</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "Connection",
	setup() {
		const phone2Text = {
			title: "您的人脉资源库",
		};
		return {
			phone2Text,
		};
	},
};
</script>
<style lang="css">
@import "./style.css";
</style>
