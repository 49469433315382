<template>
  <div class="Backlog row calendar tabCont">
    <div class="img col-3">
      <img src="../../assets/imgs/product/commission_phone.svg" alt="" />
    </div>
    <div class="text col-6">
      <h2>想做的事情，无一遗漏</h2>
      <p>
        想做还没来得及做的事情，可一键创建待办，<br class="show"/>在待办列表中，轻松管理未完成/已完成待办，<br class="show"/>可随时取消勾选、方便找回。<br/><br class="hiden"/>
        待办和事件自动建立关联，<br class="show"/>让待办更为清晰无一遗漏，保证高效落地执行。

      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Backlog",
};
</script>
<style lang="css">
@import "./style.css";
</style>
