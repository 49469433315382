<template>
	<div class="calendarConnection row tabCont">
		<div class="text col-3 hiden">
			<h2>记事又记人</h2>
			<p>
				事件添加参与人，自动生成人脉互动记录。通过将事件与人脉关联，实现双向信息沉淀。既能系统化追踪事务进展，又能自动生成互动记录，帮助用户精准复盘人际互动情况。
			</p>
		</div>
		<div class="img col-2">
			<img src="../../assets/imgs/phone2.svg" alt="" />
		</div>
		<div class="text col-3 show">
			<h2>记事又记人</h2>
			<p>
				事件添加参与人，自动生成人脉互动记录。通过将事件与人脉关联，实现双向信息沉淀。既能系统化追踪事务进展，又能自动生成互动记录，帮助用户精准复盘人际互动情况。
			</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "CalendarEvents2",
};
</script>
<style lang="css">
@import "./style.css";

</style>
